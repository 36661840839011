<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <v-row style="margin: 0px">
          <v-col class="col-12" sm="12" md="12">
            <v-list>
              <v-list-item style="padding-left: 10px">
                <v-list-item-content>
                  <v-flex xs12 sm12 md12 style="padding: 0px 25px">
                    <v-layout row>
                      <v-switch
                        v-model="permissions.crm"
                        :label="'CRM'"
                        color="primary"
                        @change="crmPermission()"
                      ></v-switch>

                      <!-- <v-btn icon @click.native="crmPermission">
                              <v-icon style="color: #fafafa">arrow_back</v-icon>
                            </v-btn> -->
                    </v-layout>
                  </v-flex>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import countries from "../js/countries";
import fb from "../js/firebase";

export default {
  components: {},
  data: () => ({
    detailUser: {},
    permissions: {
      crm: false,
    },
  }),
  computed: {
    ...mapState(["ENDPOINT", "user", "teams"]),
  },

  created() {
    var self = this;

    self.getCurrentUsetDetails();

    //this.permissions.crm = self.detailUser.permissions.crm
  },

  methods: {
    async getCurrentUsetDetails() {
      var self = this;

      await self.$http
        .get(this.ENDPOINT + "/accounts/" + this.user.id, {
          headers: {
            authorization: this.user.token,
          },
        })
        .then((response) => {
          var user = response.body;
          var token = response.body.id;
          user.token = token;

          self.detailUser = JSON.parse(JSON.stringify(user));

          this.permissions.crm = self.detailUser.permissions.crm;
          // self.$store.dispatch("setUser", user);
        });
    },
    crmPermission() {
      var permission = {
        permissions: {
          crm: this.permissions.crm,
        },
      };
      const self = this;

      self.$http
        .patch(`${self.ENDPOINT}/accounts/${this.user.id}`, permission)
        .then((response) => {
          try {
            fb.log("crm_permission");
          } catch (e) {
            console.log("Error in FB log", e);
          }
          this.$swal({ type: "success", text: "Successful" });
          this.getCurrentUsetDetails();
        });
    },
    refresh() {},
  },
};
</script>
